/* Theme color vars */
@accentColor: rgba(0, 42, 143, 1);
@accentColor80: rgba(0, 42, 143, 0.8);
@accentColor70: rgba(0, 42, 143, 0.7);
@accentColor70Solid: #4D6AB1;
@accentColor50: rgba(0, 42, 143, 0.5);
@accentColor40: rgba(0, 42, 143, 0.4);
@accentColor30: rgba(0, 42, 143, 0.3);
@accentColor20: rgba(0, 42, 143, 0.2);
@accentColor10: rgba(0, 42, 143, 0.1);
@accentColor075: rgba(0, 42, 143, 0.075);
@accentColor10Solid: #E6EAEF;
@accentColor5: rgba(0, 42, 143, 0.05);
@accentColor5Solid: #F2F4F7;
@accentColor3: rgba(0, 42, 143, 0.03);
@accentHEX: #002a8f;

@escHexAccentColor: escape(@accentHEX);

:root {
  // Colors
  // Primary
  --color-primary-50: #E6EAF4;
  --color-primary-75: #D8DEEE;
  --color-primary-100: #B0BDDD;
  --color-primary-200: #899DCB;
  --color-primary-300: #627CBA;
  --color-primary-400: #3B5BA9;
  --color-primary-500: #133A98;
  --color-primary-600: #002a8f;
  --color-primary-700: #00206E;
  --color-primary-800: #001A58;
  --color-primary-900: #001342;
  --color-primary-1000: #000D2C;
  --color-primary-1100: #000616;
}

body {
  &.modern-theme {
    .brand-holder {
      img {
        max-width: 278px;
      }
    }

    // Temporary Carousel tooltips translation issue
    .ui.popup.center.transition:is(.right, .top) {
      display: none !important;
      animation: none !important;
      z-index: -999 !important;
    }
  }
}